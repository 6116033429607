import React from "react";
import PropTypes from "prop-types";
import "./ZigZagBanner.scss";

const ZigzagBanner = ({ label }) => {
	return (
		<div className="zigzag-banner">
			<div className="zigzag-banner__content">{label}</div>
		</div>
	);
};

ZigzagBanner.propTypes = {
	label: PropTypes.string,
};

export default React.memo(ZigzagBanner);
